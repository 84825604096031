import {UserManagementState} from "../../pages/UserPage/constants";
import {LoginState} from "../../pages/LoginPage/constants";
import {OverviewState} from "../../pages/OverviewPage/constants";
import {OperationDetailState} from "../../pages/OperationDetailPage/constants";
import {DashboardState} from "../../pages/DashboardPage/constants";
import {PlanOperationState, SupportType} from "../../pages/PlanOperationPage/constants";
import {VesselDetailState} from "../../pages/VesselDetailPage/constants";
import {ChecklistState} from "../../pages/ChecklistPage/constants";
import {ConfigurationState} from "../../pages/ConfigurationPage/constants";
import {VesselManagementState} from "../../pages/VesselManagementPage/constants";
import {WeatherState} from "../../pages/WeatherPage/constants";
import {SiteMonitoringState} from "../../pages/SiteMonitoringPage/constants";
import {TaskTypeManagementState} from "../../pages/TaskTypeManagementPage/constants";
import {CombineTreatmentState} from "../../pages/CombineTreatmentPage/constants";
import {MessageState} from "../../pages/MessagePage/constants";
import {RouteState} from "../../pages/RoutePage/constatns";
import {DiseaseState} from "../../pages/DiseasePage/constants";
import {
    ACTION_TYPE,
    AVAILABLE_TIME_TYPE,
    CHAT_TYPE,
    COMBINE_TREATMENT_LEVEL,
    CONTRACT_TYPE,
    CUSTOMER_TYPE,
    DELIVERY_TYPE,
    DISEASED_ZONE,
    EVENT_TYPE,
    KeyChangeLog,
    KeyOfStep,
    LOCATION_TYPE,
    LOG_STATUS,
    MESSAGE_STATUS,
    NOTE_TYPE,
    NotificationType,
    OP_STATUS,
    OPERATION_CONFIRM_STATUS,
    OPERATION_TYPE,
    PriorityTime,
    SITE_TYPE,
    SSO_MODE,
    STORAGE_TYPE,
    TASK_TYPE,
    TREATMENT_TYPE,
    USER_ROLE,
    VALIDATION_STATUS,
    VESSEL_TYPE,
    WEATHER_STATUS
} from "./constants";
import {ReactElement, ReactNode} from "react";
import {LocationState} from "../store/location/constants";

export interface IStateGlobal {
    user: UserManagementState;
    login: LoginState
    overview: OverviewState
    route: RouteState
    operationDetail: OperationDetailState
    dashboard: DashboardState
    planOperation: PlanOperationState
    vesselDetail: VesselDetailState
    checklist: ChecklistState
    configuration: ConfigurationState
    vesselManagement: VesselManagementState
    weather: WeatherState
    siteMonitoring: SiteMonitoringState
    taskType: TaskTypeManagementState
    location: LocationState,
    combineTreatment: CombineTreatmentState,
    message: MessageState
    disease: DiseaseState
}

export interface IPosition {
    longitude: number
    latitude: number
    depth?: number
}

export interface IProductionArea {
    id: string
    name: string
    coordinates: [][]
    color: string
}

export interface IServiceTask {
    id: string
    name: string
    capabilities: ICapabilities[]
}

export interface ICapabilities {
    id: string
    name: string
}

export interface ILocation {
    id: string
    name: string
    field?: LOCATION_TYPE
    type?: LOCATION_TYPE
    region_id: string
    phone?: string
    work_time?: number | '-'
    capabilities: { id: string, name: string }[]
    diseases?: any
    position: {
        latitude: number
        longitude: number
    }
}

export interface IDisease {
    id: string
    name: string
    description: string
    is_active: boolean
}

export interface IDiseaseZone {
    diseasedType: DISEASED_ZONE
    forskNavn: string
    forskLink: string
    forskNr: string
    originalDate: string
    fromDate: string
}

export interface IFactory {
    id?: string
    name: string
    address: string
    phone: string
    work_time: number
    tag: string
    capacity_discharge: number
    plan: any[]
    position: IPosition
    deliver_type: any
}

export interface ISite {
    id: string
    name: string
    code?: number
    type?: SITE_TYPE
    position: IPosition
    address: string
    site_type: string
    region_id: string
    primary_factory?: string
    primary_factory_id?: string
    units: IUnit[]
    diseases?: any
    prod_area?: string
}

export interface IUnit {
    id: string
    unit_id: string
    harvest_id?: string
    fish_amount: number
    avg_weight: number
    total_weight: number
    mortality_risk: any
    type: string
    checked: boolean
    is_waiting_unit: boolean
    delivery_types: any
    unit_diseases?: IUnitDisease
    note?: string
    site_id?: string
    site_name?: string
    diseases?: any
    fish_group?: string
    primary_factory?: string
    round_number?: number
    site_type?: SITE_TYPE
    storages?: IUnitStorage[]
    starving_days?: number
    lice?: ILiceInfo
    lice_reduction?: any
    selected?: boolean                          // Operations table - Site chart - Plan page
    mortality_increase?: any
}

export interface IUnitDisease {
    site_id: string
    types: IDisease[]
}

export interface IUnitStorage extends IStorage {
    density: number
    total_weight: number
    fish_amount: number
}

export interface ISchedule {
    name: string
    localityNo?: any
    disease?: any
    diseases?: any
    time: number
    location_type: "disease" | "site" | "factory"
    status: SCHEDULE_STATUS
}

export enum SCHEDULE_STATUS {
    ENTER = 'enter',
    LEAVE = 'leave'
}

export interface IChecklist {
    id: string
    tags: string[]
    content: string
    vessel_id: string
    step: number
    user_id: string
    operation_type: number
    step_name?: string
    result?: string
    is_active?: boolean
    version?: string
    group_id?: string
}

export interface IVessel {
    id: string
    name: string
    contracts: IContract[]
    capabilities: any[]
    capacity: number
    operation_type: any[]
    primary_factory: string
    type: VESSEL_TYPE
    treatment_type: any[]
    operation_sub_types: any[]
    tenant_id: string
    index: number
    isShow?: boolean
    isOwn?: boolean
    enable_spot?: boolean
    image_url?: string
    permission: any
    available_times: IAvailableTime[]
    available_time_type: AVAILABLE_TIME_TYPE
    iot_name?: string
    gps?: any
    row?: number
    storages?: IStorage[]
    priority?: PriorityTime
    isPermission?: boolean
    harvest_capacity?: number
    factory_discharge?: number
    waiting_unit_capacity?: number
    cleaning_time?: number
    position?: {
        latitude: number
        longitude: number
    }
}

export interface ILiceInfo {
    female_lice?: { value: number, state?: string },
    mobile_lice?: { value: number, state?: string },
    stationary_lice?: { value: number, state?: string },
    total_lice?: { value: number, state?: string },
    tenant_id?: string,
}

export interface IDiseaseOfSite {
    cases: any[]
    disease_zone: any //"surveillance"

    diseases: any
    lice?: ILiceInfo
    id: string
    name: string
    pk: string
    production_area: {
        name: string
        id: number
    }
    site_id: string
    site_number: string
    tenant_id: string
}

interface IDetailOperation {
    id: string
    operation_code: string
    operation_type: OPERATION_TYPE
    status: OP_STATUS
    approver?: string
    certain_mode?: any
    diseases?: IDiseaseOfSite[]
    checklists?: any
    current_process: number
    tenant_name?: string
    typical_condition?: WEATHER_STATUS
    sorting?: boolean
    router_id?: string
    contract_id?: string
    available_time_id?: string
    support?: SupportType
    processes?: IActivityLog[]
    mortality_increase?: number
    avg_duration?: number
    lice_reduction?: {
        stationary_lice?: number
        mobile_lice?: number
        female_lice?: number
    }
}

export interface IHarvest extends IDetailOperation {
    site_id: string
    site_name: string
    sub_operations: IUnit[]
    factory_id: string
    factory_name: string
    is_waiting_unit: boolean
    router_id?: string
    prev_op_router_id: string
    start_place_id: string
    start_place_field: string
    is_active_vessel: boolean
    tenant_id: string
    vessel_id: string
    total_weight: number
    fish_amount: number
    sites?: any
    site?: any
    source?: any
    factory?: any
    destination?: any
    related_id?: any
    related_operations?: any
    source_site_type?: SITE_TYPE
    total_amount?: number
    delivery_types?: any
    is_journey_to_site?: boolean
    is_cleaning?: boolean
    error_detail?: any
}

export interface ITreatment extends IDetailOperation {
    site: ISite
    site_name: string
    sub_operations: any[]
    is_active_vessel: boolean
    support_vessel: any
    is_cleaning: boolean
    tasks: any[]
    unit_order?: {
        unit_id: string
        id: string
    }[]
    files?: string[]
}

export interface ITransport extends IDetailOperation {
    router_id?: string
    prev_op_router_id: string
    is_active_vessel: boolean
    start_place_id: string
    start_place_field: string
    source_id: string
    source_name: string
    destination_name: string
    sub_destinations?: IUnit[]
    source_site_type?: SITE_TYPE
    destination_site_type?: SITE_TYPE
    destination_id?: string
}

export interface IEvent extends IDetailOperation {
    event_type: EVENT_TYPE
    note: string
    is_active_vessel: boolean
    parallel?: boolean
    pois?: ILocation[] | { id: string, name: string }[]
}

export interface IService extends IDetailOperation {
    site: ISite
    site_id: string
    site_name: string
    sub_operations: any[]
    is_active_vessel: boolean
    tasks?: any[]
}

export type TOperation = IHarvest & ITreatment & ITransport & IEvent & IService

export interface IOperation {
    action_type?: OPERATION_CONFIRM_STATUS
    vessel: IVessel
    activity_log: IActivityLog[]
    operation: TOperation
    status?: VALIDATION_STATUS
    message?: string
    est_start_time?: number,
    checklists?: IChecklistsOfOp
    route?: IRoute
    isShow?: boolean
    prev_frame?: IOperation
    next_frame?: IOperation
}

export interface IChecklistsOfOp {
    [step: string]: IChecklist
}

export interface IOperations {
    [index: string]: IOperation
}

export interface ILevelByVessel {
    [vesselId: string]: ILevelByOp
}

export interface ILevelByOp {
    [opId: string]: ILevel
}

export interface ILevel {
    main: boolean
    operation_code: string
    level: number
    sub: number
    ids: string[]
}

export interface IActivityLog {
    est_start_time: number
    key?: KeyOfStep | any
    index?: number
    place?: string
    real_start_time?: number
    eta?: number
    duration?: number
    resting_duration?: number
    group_id?: string
    unit_id?: string
    round?: string
    name?: string                                   // Location name
}

export interface IGroupActivityLog {
    id: string
    name: string
    list: IActivityLog[]
}

export interface INoteCalender {
    id: string
    level: NOTE_TYPE
    note: string
    tenant_id: string
    timestamp: number
    user: {
        username: string
        roles: USER_ROLE[]
    }
}

export interface IStorage {
    id: string
    name: string
    type: STORAGE_TYPE
    value: number
}

export interface IAvailableTime {
    id: string
    start_time: number
    finish_time: number
    vessel_id: string
}

export interface IContract {
    id: string
    type: CONTRACT_TYPE
    tenant_id: string
    start_time: number
    finish_time: number
    due_time: number
    contract_code: string
}

export interface ITenant {
    id: string
    tenant_id: string
    name: string
    weather_license?: boolean
    customer_type: CUSTOMER_TYPE
}

export interface IPropsChildren {
    children?: ReactNode | ReactElement | ReactNode[]
}

export interface IPoint {
    longitude: number
    latitude: number
    depth: number
    point_id: number
    name: string
}

export interface IRoute {
    id: string
    source: any
    source_id: any
    destination: any
    destination_id: any
    default: boolean
    name: string
    distance: number
    points: IPoint[]
}

export interface IRegion {
    id: string
    name: string
}

export interface IDomain {
    domain: string
    provider_name: string
    sso_mode: SSO_MODE
}

export interface ILatLng {
    lat: number
    lng: number
}

export interface IGroupChat {
    field: string
    host_id: string
    id: string
    members: {
        id: string
        username: string
    }[]
    name: string
}

export interface IMessage {
    id: string
    chat_id: string
    message: string
    roles: USER_ROLE[]
    timestamp: number
    user_tenant_name: string
    username: string
    seen?: {
        [id: string]: {
            timestamp: number
            username: string
        }
    }
    status?: MESSAGE_STATUS
    sending_status?: string
}

export interface IMessages {
    [groupId: string]: IChatBox
}

export interface IChatBox {
    id: string
    loading: boolean
    prev?: boolean
    next?: boolean
    messages: IMessage[]
}

export interface IConversation {
    id: string
    group_id: string
    group_chat: IGroupChat
    chat_id?: string
    message: IMessage
    message_id: string
    status: MESSAGE_STATUS
    timestamp: number
    type: CHAT_TYPE
}

export interface IIdName {
    id: any
    name: string
}

export interface IWeather {
    id: string
    name: string
    is_enable: boolean
    type: string
    group_id: string
    bad_condition: string
    moderate_condition: string
    good_condition: string
    actionType?: ACTION_TYPE
}

export interface ISTHP {
    harvest_id: string
    status: string
    unit_number: string
    factory_id: string
    factory_name: string
    fish_amount: number
    harvest_date: string
    original_fish_amount: number
    packing_date: string
    planned_amount: number
    region_id: string
    site_id: string
    site_name: string
    tenant_id: string
    total_weight: number
    unit_id: string
}

export interface ISubCombineTreatment {
    name: string
    type: TASK_TYPE
    treatment_type: TREATMENT_TYPE
    duration: number
    durationTxt?: string
}

export interface ICombineTreatment {
    id: string
    name: string
    sub_types: ISubCombineTreatment[]
    tenant_id: number
    level: COMBINE_TREATMENT_LEVEL
    type: TASK_TYPE.COMBINE
}

export interface IDiffItem {
    new: any
    old: any
    state: LOG_STATUS
}

export interface IDiffUnits {
    [unitId: string]: {
        unit_id: string             // name of unit
        state: LOG_STATUS
        [KeyChangeLog.TASKS]?: IDiffItem[],
        [KeyChangeLog.TOTAL_WEIGHT]?: {
            new: number
            old: number
        }
        [KeyChangeLog.FISH_AMOUNT]?: {
            new: number
            old: number
        }
        [KeyChangeLog.NOTE]?: {
            new: number
            old: number
            state: LOG_STATUS
        }
        [KeyChangeLog.DELIVERY_TYPES]?: {
            new: {
                id: string
                name: string
                [key: string]: any
            }[]
            old: {
                id: string
                name: string
                [key: string]: any
            }[]
        }
    }
}

export interface IDifferenceLogs {
    [KeyChangeLog.SUB_OPS]?: IDiffUnits

    [KeyChangeLog.DURATION]?: {
        new: number
        old: number
        state: LOG_STATUS
    }

    [KeyChangeLog.START_TIME]?: {
        new: number
        old: number
        state: LOG_STATUS
    }

    [KeyChangeLog.FINISH_TIME]?: {
        new: number
        old: number
        state: LOG_STATUS
    }

    [KeyChangeLog.TASKS]?: {
        new: number
        old: number
        state: LOG_STATUS
    }

    [KeyChangeLog.STATUS]?: {
        new: OP_STATUS
        old: OP_STATUS
        state: LOG_STATUS
    }
    [KeyChangeLog.DELAY_TIME]?: {
        new: {
            key: string
            time_late: number
        }
        state: LOG_STATUS
    }

    [key: string]: any
}

export interface IOperationLog {
    differences: IDifferenceLogs
    operation_type: OPERATION_TYPE
    operation_id: string
    operation_code: string
    site_name: string
    vessel_id: string
    state: LOG_STATUS
}

export interface IChangeLog {
    timestamp: number
    username: string
    operation: {
        [id: string]: IOperationLog
    }
}


export interface INotification {
    id: string
    type: string
    message: string
    notificationType?: NotificationType
    timestamp: number
    detail: any
    read: boolean
}

export interface IVesselConfig {
    delivery_type: DELIVERY_TYPE,
    priority: PriorityTime,
    is_journey_to_site: boolean,
    cleaning_types: DELIVERY_TYPE[]
}
