import {OPERATION_TYPE, TASK_TYPE, TREATMENT_TYPE, VESSEL_TYPE} from "../../../../../util/varibles/constants";
import {addTask as addTaskTreatment, getTaskSupport} from "./Treatment/constants";
import {addTask as addTaskService} from "./Service/constants";
import {ISite, IUnit, IVessel, TOperation} from "../../../../../util/varibles/interface";
import {getStorages} from "../../../util/function_operation/constants";
import {checkRoute, getTotalAmountAndTotalWeightByDeliveryType} from "../../../util";
import {notify, NotifyCode} from "../../../../../util/varibles/message";
import {cloneObj, uuid} from "../../../../../util/varibles/global";
import {DELIVERY_UNIT} from "./Harvest/constants";

export enum UpdateName {
    // unit
    ADD_UNIT = 'add/unit',
    DELETE_UNIT = 'delete/unit',
    UPDATE_UNIT = 'update/unit',
    UPDATE_UNIT_NOTE = 'update/unit/note',
    UPDATE_UNIT_POSITION = 'update/unit/position',


    // task
    ADD_TASK = 'add/task',
    DELETE_TASK = 'delete/task',
    UPDATE_TASK = 'update/task',
    UPDATE_TASK_POSITION = 'update/task/position',
    DUPLICATE = 'task/duplicate',
    FACTORY = 'factory',
    SUPPORT = 'task/support',

    // Harvest
    MORTALITY_RISK = 'mortality_risk',
    DELIVERY_TYPES = 'delivery_types',

    // global
    UPDATE_GLOBAL = 'update/global',
    SELECT_VESSEL_SP = 'select/vessel/support',

    NOT_CALCULATE = 'update/not_calculate',
    CALCULATE = 'update/calculate',
}

interface ResultUpdateName {
    isCalculate: boolean

    [index: string]: any
}

interface IParamsUpdateData {
    state: {
        isCalculate: boolean
        operations: TOperation[]
        operation: TOperation
        units: { [id: string]: IUnit }
        sites: ISite[]
        store: { [id: string]: IUnit }
        supportTasks: any
    }
    props: any
    args: any
}

function updateAmountAndWeightOfSub(sub: any) {
    const {delivery_types} = sub;
    return {
        ...sub, ...delivery_types.reduce((rs: any, item: any) => {
            rs.total_weight += item.total_weight;
            rs.fish_amount += item.fish_amount;
            return rs;
        }, {total_weight: 0, fish_amount: 0})
    }
}


const common = {
    [UpdateName.NOT_CALCULATE]: (params: IParamsUpdateData): ResultUpdateName => {
        return updateOpSimple(params.state, params.args);
    },
    [UpdateName.CALCULATE]: (params: IParamsUpdateData): ResultUpdateName => {
        return updateOpSimple(params.state, params.args, true);
    },
}

const harvest = {
    ...common,
    [UpdateName.FACTORY]: (params: IParamsUpdateData) => {
        const {operations} = params.state;
        const {routes} = params.props;
        const {value, indexHarvest} = params.args;
        if (!operations[indexHarvest])
            return {};

        const {sites} = operations[indexHarvest];
        const error_detail = cloneObj(operations[indexHarvest]?.error_detail || {});
        let isRoute = false;
        const newSite = sites.map((sub: any) => {
            const {error} = checkRoute(sub, value, routes);
            if (error !== 2) {
                isRoute = true;
                return {...sub, isRoute: true}
            }
            return {...sub, isRoute: false}
        })
        if (!isRoute) {
            error_detail.route = sites.map((item: any) => ({
                source_id: item.id,
                destination_id: value.id,
                message: notify[NotifyCode.E20]([item.name, value.name])
            }));
        } else {
            delete error_detail.route;
        }
        Object.assign(operations[indexHarvest], {
            ...operations[indexHarvest],
            factory_id: value.id,
            sites: newSite,
            factory_name: value.name,
            delivery_types: value.deliver_type || {},
            error_detail
        })
        const {router_id, ...opAfterDeleteRouterId} = operations[indexHarvest];
        operations.splice(indexHarvest, 1, opAfterDeleteRouterId)
        return {isCalculate: true, operations}
    },
    [UpdateName.MORTALITY_RISK]: (params: IParamsUpdateData) => {
        const {operations} = params.state;
        const {value, indexHarvest, indexUnit} = params.args;
        const {sub_operations} = operations[indexHarvest];

        sub_operations[indexUnit] = {...sub_operations[indexUnit], mortality_risk: value};
        operations[indexHarvest] = {...operations[indexHarvest], sub_operations};
        return {operations}
    },
    [UpdateName.DELIVERY_TYPES]: (params: IParamsUpdateData) => {
        let {operations, store} = params.state;
        const {vessel} = params.props.popupOperation.values;
        const {value, indexHarvest, indexUnit, indexDeliveryType, isDeliveryTypeDefault} = params.args;
        if (!operations[indexHarvest])
            return {}
        const {sub_operations, total_weight: totalWeightRoot}: any = operations[indexHarvest];
        const {capacity} = vessel;


        if (isDeliveryTypeDefault) {
            const {avg_weight, id, harvest_id} = operations[indexHarvest].sub_operations[indexUnit];
            const {
                unit,
                fish_amount: oldFishAmount,
                total_weight: oldTotalWeight
            } = sub_operations[indexUnit].delivery_types[indexDeliveryType];
            const keyStore = id + '|' + harvest_id;
            const {fish_amount: amountStore} = store[keyStore]

            let fish_amount, total_weight;

            if (unit === DELIVERY_UNIT.TONS || avg_weight < 0) {
                const {total_weight: totalWeightStore} = store[keyStore];
                const maxValue = oldTotalWeight + totalWeightStore;
                if (avg_weight > 0 && value > maxValue)
                    notify.warn(NotifyCode.W6)();
                const newTotalWeightRoot = totalWeightRoot - oldTotalWeight + value;
                if (newTotalWeightRoot > capacity) {
                    notify.error(NotifyCode.E1)();
                    const subTotalWeight = capacity - totalWeightRoot + oldTotalWeight;
                    fish_amount = avg_weight < 0 ? 0 : Math.floor(subTotalWeight / avg_weight);
                    total_weight = avg_weight < 0 ? subTotalWeight : fish_amount * avg_weight;
                } else {
                    fish_amount = avg_weight < 0 ? 0 : Math.floor(value / avg_weight);
                    total_weight = value;
                }
            } else {
                const maxValue = oldFishAmount + amountStore;
                if (avg_weight > 0 && value > maxValue)
                    notify.warn(NotifyCode.W6)();
                const newTotalWeightRoot = totalWeightRoot - ((oldFishAmount + value) * avg_weight);
                if (newTotalWeightRoot > capacity) {
                    notify.error(NotifyCode.E1)();
                    const subTotalWeight = capacity - totalWeightRoot + (oldFishAmount * avg_weight);
                    fish_amount = Math.floor(subTotalWeight / avg_weight);
                    total_weight = fish_amount * avg_weight;
                } else {
                    fish_amount = value;
                    total_weight = value * avg_weight;
                }
            }
            sub_operations[indexUnit].delivery_types[indexDeliveryType] = {
                ...sub_operations[indexUnit].delivery_types[indexDeliveryType],
                fish_amount,
                total_weight
            };
            sub_operations[indexUnit] = updateAmountAndWeightOfSub(sub_operations[indexUnit]);
            const newAmountStore = amountStore + oldFishAmount - fish_amount;
            store = {
                ...store,
                [keyStore]: {
                    ...store[keyStore],
                    fish_amount: newAmountStore,
                    total_weight: newAmountStore * avg_weight
                }
            }
            operations[indexHarvest] = {
                ...operations[indexHarvest],
                ...getTotalAmountAndTotalWeightByDeliveryType(sub_operations)
            }
        } else {
            sub_operations[indexUnit] = updateAmountAndWeightOfSub({
                ...sub_operations[indexUnit],
                delivery_types: value
            });
            operations[indexHarvest] = {...operations[indexHarvest], ...getTotalAmountAndTotalWeightByDeliveryType(sub_operations)};
        }

        const {total_amount, total_weight} = operations[indexHarvest];
        const {storages: old = []} = operations[indexHarvest].sub_operations[0] || {};
        const storages = getStorages({fish_amount: total_amount || 0, total_weight, storages: old}, vessel);
        if (storages.length > 0)
            Object.assign(operations[indexHarvest].sub_operations, operations[indexHarvest].sub_operations.map((item: IUnit) => ({
                ...item,
                storages
            })))

        return {operations, store, isCalculate: true};
    },
}

const treatment = {
    ...common,
    [UpdateName.ADD_UNIT]: (params: IParamsUpdateData): ResultUpdateName => {
        const {operation, units} = params.state;
        const {vessel} = params.props.popupOperation.values;
        const newUnits: any = {};
        const tasks = params.args.units.reduce((data: any, unit: IUnit) => {
            const {fish_amount, total_weight} = unit;
            newUnits[unit.id] = {
                ...unit,
                round_number: calculateRound(total_weight, vessel),
                storages: getStorages({fish_amount, total_weight}, vessel)
            };
            return addTaskTreatment(data, unit, vessel);
        }, operation.tasks || [])

        return {
            isCalculate: params.state.isCalculate || false,
            operation: {...operation, tasks},
            units: {...newUnits, ...units}
        };
    },

    [UpdateName.DELETE_UNIT]: (params: IParamsUpdateData): ResultUpdateName => {
        const {unit} = params.args;
        const {operation} = params.state;
        const {tasks} = params.state.operation;

        const newTasks = tasks.filter((item: any) => {
            const {id = null} = item.sub_operation || item.sub_operations[0] || {};
            return id !== unit.id
        })

        return {
            isCalculate: true,
            operation: {...operation, tasks: newTasks},
            supportTasks: getTaskSupport(newTasks)
        };
    },
    [UpdateName.UPDATE_UNIT]: (params: IParamsUpdateData): ResultUpdateName => {
        const {units} = params.state;
        const {unit} = params.args;
        return {
            isCalculate: true,
            units: {...units, [unit.id]: unit}
        };
    },
    [UpdateName.UPDATE_UNIT_NOTE]: (params: IParamsUpdateData): ResultUpdateName => {
        const {units} = params.state;
        const {unit} = params.args;
        return {
            isCalculate: params.state.isCalculate || false,
            units: {...units, [unit.id]: unit}
        };
    },
    [UpdateName.UPDATE_UNIT_POSITION]: (params: IParamsUpdateData): ResultUpdateName => {
        const {operation} = params.state;
        const {targetId, index} = params.args;
        const tasks = changePositionUnit({operation, targetId, index});
        return {
            isCalculate: true,
            operation: {...operation, tasks},
        };
    },
    [UpdateName.ADD_TASK]: (params: IParamsUpdateData): ResultUpdateName => {
        const {operation} = params.state;
        const {unit} = params.args;
        const {vessel} = params.props.popupOperation.values;
        const tasks = addTaskTreatment(JSON.parse(JSON.stringify(operation.tasks)), unit, vessel);

        return {
            isCalculate: params.state.isCalculate || false,
            operation: {...operation, tasks},
        };
    },
    [UpdateName.DELETE_TASK]: (params: IParamsUpdateData): ResultUpdateName => {
        const {operation} = params.state;
        const {index} = params.args;
        const {tasks} = operation;
        const newTasks = tasks.filter((item: any, i: number) => i !== index);
        return {
            isCalculate: true,
            operation: {...operation, tasks: newTasks},
            supportTasks: getTaskSupport(newTasks)
        }
    },
    [UpdateName.UPDATE_TASK]: (params: IParamsUpdateData): ResultUpdateName => {
        const {operation} = params.state;
        const {tasks} = operation;
        const {task} = params.args;
        const {index} = task;
        return {
            isCalculate: true,
            operation: {
                ...operation,
                tasks: tasks.map((item: any, i: number) => i === index ? task : item)
            }
        }
    },
    [UpdateName.UPDATE_TASK_POSITION]: (params: IParamsUpdateData): ResultUpdateName => {
        const {operation} = params.state;
        const tasks = changePositionTask({...params.args, operation});
        return {
            isCalculate: true,
            operation: {...operation, tasks},
        };
    },
    [UpdateName.FACTORY]: (params: IParamsUpdateData): ResultUpdateName => {
        const {operation} = params.state;
        const {tasks} = operation;
        const {task, factory} = params.args;
        const {group_id} = task;
        const state = {
            factory,
            operation: {
                ...operation,
                tasks: tasks.map((sub: any) => {
                    const {treatment_type} = sub;
                    if (treatment_type === TREATMENT_TYPE.SORTING) {
                        const {sub_tasks} = sub;
                        return {
                            ...sub,
                            sub_tasks: Object.keys(sub_tasks).reduce((subList: any, key) => {
                                subList[key] = sub_tasks[key].map((task: any) => {
                                    if (task.type !== TASK_TYPE.HARVEST || task.group_id !== group_id)
                                        return task
                                    return task;
                                })
                                return subList
                            }, {})
                        }
                    } else if (sub.type === TASK_TYPE.HARVEST && sub.group_id === group_id) {
                        return task;
                    }
                    return sub;
                })
            }
        };
        return {...state, isCalculate: true}
    },
    [UpdateName.SUPPORT]: (params: IParamsUpdateData): ResultUpdateName => {
        const {task} = params.args;
        const {supportTasks, operation} = params.state;
        const {is_support_vessel, sub_operation, sub_operations} = task;
        const {id: unitId} = sub_operation || sub_operations[0];
        const {tasks} = operation;
        const {group_id} = task;
        const key = [group_id, unitId].join('_');

        const state = {
            supportTasks: is_support_vessel
                ? [...supportTasks, task]
                : supportTasks.filter((item: any) => {
                    const {id} = item.sub_operation || item.sub_operations[0];

                    return [item.group_id, id].join('_') !== key
                }),
            operation: {
                ...operation,
                tasks: tasks.map((item: any) => {
                    const {treatment_type} = item;
                    const {id} = item.sub_operation || item.sub_operations[0];
                    if (treatment_type === TREATMENT_TYPE.SORTING) {
                        const {sub_tasks} = item;
                        return {
                            ...item,
                            sub_tasks: Object.keys(sub_tasks).reduce((subList: any, key) => {
                                subList[key] = sub_tasks[key].map((sub: any) => sub.group_id === group_id ? task : sub)
                                return subList
                            }, {})
                        }
                    } else if ([item.group_id, id].join('_') === key)
                        return task

                    return item
                })
            }
        };

        return {...state, isCalculate: true}
    },
    [UpdateName.DUPLICATE]: (params: IParamsUpdateData): ResultUpdateName => {
        const {vessel} = params.props.popupOperation.values;
        const {operation} = params.state;
        const {units, task} = params.args;
        const {type} = task;

        const group = operation.tasks.reduce((rs: any, item: any) => {
            const {id} = item.sub_operation || item.sub_operations[0];
            rs[id] = [...rs[id] || [], item]
            return rs;
        }, {});

        const newUnits: any = {};
        units.forEach((item: any) => {
            const {id, total_weight} = item;
            const round_number = calculateRound(total_weight, vessel);
            newUnits[id] = {...item, round_number};
            const common = {
                ...task,
                group_id: uuid(),
                round_number
            }
            const value = type === TASK_TYPE.HARVEST
                ? {...common, sub_operations: item}
                : {...common, sub_operation: item}
            if (group[id])
                group[id].push(value);
            else {
                group[id] = [value]
            }
        })

        const tasks: any = Object.keys(group).reduce((rs: any, key) => [...rs, ...group[key]], []);
        return {
            operation: {...operation, tasks},
            isCalculate: true,
            units: {...newUnits, ...params.state.units}
        }
    },
    [UpdateName.SELECT_VESSEL_SP]: (params: IParamsUpdateData): ResultUpdateName => {
        const {supportTasks, operation} = params.state;
        const {vessel, isSelect, listOfChecked} = params.args;
        const {tasks} = operation;
        const data = {
            id: vessel.id,
            name: vessel.name,
            tenant_id: vessel.tenant_id
        }
        return {
            isCalculate: true,
            operation: {
                ...operation,
                tasks: tasks.map((item: any) => {
                    const {type, treatment_type} = item;
                    if (type === null)
                        return item;

                    if (type === TASK_TYPE.TREATMENT && treatment_type === TREATMENT_TYPE.SORTING) {
                        const {sub_tasks} = item;
                        return {
                            ...item,
                            sub_tasks: Object.keys(sub_tasks).reduce((rs: any, key) => {
                                rs[key] = sub_tasks[key].map((sub_task: any) => {
                                    if (listOfChecked.has(sub_task.group_id)) {
                                        const {support_vessels = []} = sub_task;
                                        const isExist = support_vessels.some((sub: any) => sub.id === data.id);
                                        if (!isSelect) {
                                            return {
                                                ...sub_task,
                                                support_vessels: support_vessels.filter((sub: any) => sub.id !== data.id)
                                            }
                                        } else if (!isExist)
                                            return {...sub_task, support_vessels: [...support_vessels, data]}
                                    }
                                    return sub_task
                                })
                                return rs;
                            }, {})
                        }
                    } else if (listOfChecked.has(item.group_id)) {
                        const {support_vessels = []} = item;
                        const isExist = support_vessels.some((sub: any) => sub.id === data.id);
                        if (!isSelect) {
                            return {
                                ...item,
                                support_vessels: support_vessels.filter((sub: any) => sub.id !== data.id)
                            }
                        } else if (!isExist)
                            return {...item, support_vessels: [...support_vessels, data]}
                    }
                    return item;
                }, [])
            },
            supportTasks: supportTasks.map((item: any) => {
                const {support_vessels = [], group_id} = item;
                if (listOfChecked.has(group_id)) {
                    const isExist = support_vessels.some((sub: any) => sub.id === data.id);
                    if (!isSelect) {
                        return {
                            ...item,
                            support_vessels: support_vessels.filter((sub: any) => sub.id !== data.id)
                        }
                    } else if (!isExist)
                        return {...item, support_vessels: [...support_vessels, data]}
                }
                return item;
            })
        };
    },
};

const transport = {
    ...common,
}

const event = {
    ...common,
};

const service = {
    ...common,
    [UpdateName.ADD_UNIT]: (params: IParamsUpdateData): ResultUpdateName => {
        const {operation, units} = params.state;
        const newUnits: any = {};

        const {tasks} = params.args.units.reduce((data: any, unit: any) => {
            newUnits[unit.id] = unit;
            return addTaskService(data.tasks, unit)
        }, {tasks: cloneObj(operation.tasks)})

        return {
            isCalculate: params.state.isCalculate || false,
            operation: {...operation, tasks},
            group_id: uuid(),
            units: {...newUnits, ...units}
        };
    },

    [UpdateName.DELETE_UNIT]: (params: IParamsUpdateData): ResultUpdateName => {
        const {unit} = params.args;
        const {operation} = params.state;
        const {tasks} = params.state.operation;
        const newTasks = tasks.filter((item: any) => {
            const {id = null} = item.sub_operation || {};
            return id !== unit.id
        })

        return {
            isCalculate: true,
            operation: {...operation, tasks: newTasks},
            supportTasks: getTaskSupport(newTasks)
        };
    },
    [UpdateName.UPDATE_UNIT]: (params: IParamsUpdateData): ResultUpdateName => {
        const {units} = params.state;
        const {unit} = params.args;
        return {
            isCalculate: true,
            units: {...units, [unit.id]: unit}
        };
    },
    [UpdateName.UPDATE_UNIT_NOTE]: (params: IParamsUpdateData): ResultUpdateName => {
        const {units} = params.state;
        const {id, unit} = params.args;
        return {
            isCalculate: params.state.isCalculate || false,
            units: {...units, [id]: unit}
        };
    },
    [UpdateName.UPDATE_UNIT_POSITION]: (params: IParamsUpdateData): ResultUpdateName => {
        const {operation} = params.state;
        const {targetId, index} = params.args;
        const tasks = changePositionUnit({operation, targetId, index});
        return {
            isCalculate: true,
            operation: {...operation, tasks},
        };
    },
    [UpdateName.ADD_TASK]: (params: IParamsUpdateData): ResultUpdateName => {
        const {operation} = params.state;
        const {unit} = params.args;
        const {tasks} = addTaskService(JSON.parse(JSON.stringify(operation.tasks)), unit);

        return {
            isCalculate: params.state.isCalculate || false,
            operation: {...operation, tasks},
            group_id: uuid(),
        };
    },
    [UpdateName.DELETE_TASK]: (params: IParamsUpdateData): ResultUpdateName => {
        const operation = JSON.parse(JSON.stringify(params.state.operation));
        const {index} = params.args;
        const {tasks} = operation;
        const newTasks = tasks.filter((item: any, i: number) => i !== index);

        return {
            isCalculate: true,
            operation: {...operation, tasks: newTasks},
            supportTasks: getTaskSupport(newTasks)
        }
    },
    [UpdateName.UPDATE_TASK]: (params: IParamsUpdateData): ResultUpdateName => {
        const {operation} = params.state;
        const {tasks} = operation;
        const {task} = params.args;
        const {index} = task;
        return {
            isCalculate: true,
            operation: {
                ...operation,
                tasks: tasks.map((item: any, i: number) => i === index ? task : item)
            }
        }
    },
    [UpdateName.UPDATE_TASK_POSITION]: (params: IParamsUpdateData): ResultUpdateName => {
        const {operation} = params.state;
        const tasks = changePositionTask({...params.args, operation});
        return {
            isCalculate: true,
            operation: {...operation, tasks},
        };
    },
}

export const updateData: any = {
    [OPERATION_TYPE.HARVEST]: harvest,

    [OPERATION_TYPE.TREATMENT]: treatment,

    [OPERATION_TYPE.TRANSPORT]: transport,

    [OPERATION_TYPE.EVENT]: event,

    [OPERATION_TYPE.SERVICE]: service
}

interface IChangePositionUnit {
    operation: any
    targetId: string
    index: number
}

export function changePositionUnit(params: IChangePositionUnit) {
    const {operation, targetId, index} = params
    const {tasks = []} = operation || {};
    let listOfUnit: any = [], oldIndex = -1;
    const groupByUnit = tasks.reduce((rs: any, item: any) => {
        const {id} = item.sub_operation || item.sub_operations[0];
        if (!rs[id]) {
            if (id === targetId)
                oldIndex = listOfUnit.length
            rs[id] = [item];
            listOfUnit.push((id));
        } else
            rs[id].push(item);
        return rs;
    }, {});

    if (oldIndex > index) {
        listOfUnit.splice(oldIndex, 1);
        listOfUnit.splice(index, 0, targetId);
    } else {
        listOfUnit.splice(index, 0, targetId);
        listOfUnit.splice(oldIndex, 1);
    }
    return listOfUnit.reduce((list: any, key: string) => [...list, ...groupByUnit[key]], []);
}

interface IChangePositionTask {
    newPosition: any
    oldPosition: any
    operation: any
    newUnitId: string
    oldUnitId: string
}

export function changePositionTask(params: IChangePositionTask) {
    const {newPosition, oldPosition, operation} = params;
    const [index, condition, parentIndex = null] = newPosition.split('-');
    const [oldIndex, oldCondition, oldParentIndex = null] = oldPosition.split('-');

    let isDelete;
    if (parentIndex !== null && oldParentIndex !== null) {
        isDelete = parentIndex === oldParentIndex && condition === oldCondition && +oldIndex > +index
    } else {
        const current = `${(oldParentIndex || oldIndex)}${oldIndex}`;
        const target = `${(parentIndex || index)}${index}`;
        isDelete = +current[0] >= +target[0];
    }

    let {tasks}: any = operation;
    if (isDelete) {
        let data;
        if (oldParentIndex) {
            data = tasks[oldParentIndex].sub_tasks[oldCondition][oldIndex];
            tasks[oldParentIndex].sub_tasks[oldCondition] = tasks[oldParentIndex].sub_tasks[oldCondition].filter((item: any, i: any) => i !== +oldIndex)
        } else {
            data = tasks[oldIndex];
            tasks = tasks.filter((item: any, i: number) => i !== +oldIndex);
        }
        if (parentIndex)
            tasks[parentIndex].sub_tasks[condition].splice(index, 0, data);
        else
            tasks.splice(index, 0, data);
    } else {
        const list = parentIndex ? tasks[parentIndex].sub_tasks[condition] : tasks;

        if (oldParentIndex) {
            const data = tasks[oldParentIndex].sub_tasks[oldCondition][oldIndex];
            list.splice(index, 0, data);
            tasks[oldParentIndex].sub_tasks[oldCondition] = tasks[oldParentIndex].sub_tasks[oldCondition].filter((item: any, i: any) => i !== +oldIndex)
        } else {
            const data = tasks[oldIndex];
            list.splice(index, 0, data);
            tasks = tasks.filter((item: any, i: number) => i !== +oldIndex);
        }
    }
    return tasks;
}

export function updateOpSimple(state: any, args: any, isCalculate = false) {
    const {index, ...value} = args;
    if (index !== undefined) {
        const {operations} = state;
        const operation = {...operations[index]}
        Object.assign(operations[index], {...operation, ...value});
        return {
            isCalculate: state.isCalculate || isCalculate,
            operations
        }
    } else {
        const {operation} = state;
        return {
            isCalculate: state.isCalculate || isCalculate,
            operation: {...operation, ...value},
        };
    }

}

export function calculateRound(total: number, vessel: IVessel): number {
    const {type, capacity} = vessel;
    return type === VESSEL_TYPE.WELL_BOAT ? (Math.ceil(total / (capacity * .7)) || 1) : 1
}

export function calculateDensity(total: number, volume: number) {
    if (!volume)
        return 0

    return Math.round(total / 1000 / volume) / 1000
}
